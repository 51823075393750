<template>
  <v-card class="rd elevation-0" >
    <img class="img" :src="src"  width="100%" max-height="100%" @click="imgClick" style="cursor: pointer" />

    <span class="name">{{ pageValue.name || "No Name" }}</span>
    <div class="nfttype" v-if="this.collectsource == 'createCollect'">
      <div class="type">{{721}}</div>
    </div>
    <span class="num" :class="{otherNum:this.collectsource != 'createCollect'}" v-if="addType == 'addFolder'">{{ pageValue.amount || 0 }} </span>
    <span class="num" :class="{otherNum:this.collectsource != 'createCollect'}" v-else>{{ pageValue.nftCount || 0 }} </span>  
    <div class="avatar" v-if="!isMobile && this.collectsource != 'createFolder'">
       <Avatar  :size="42" imgType="middlev"  :grade="pageValue.grade" :did="pageValue.owner" :hash="pageValue.profilePhoto" showAvatarInfo/>
    </div>
     <div class="mobileavatar" v-if="isMobile">
       <Avatar  :size="42" imgType="middlev"  :grade="pageValue.grade" :did="pageValue.owner" :hash="pageValue.profilePhoto" showAvatarInfo/>
    </div>
    <div class="showIcon" v-if="pageValue.collectionType == '1' && this.collectsource != 'createCollect'">
        <img
								class="icon_collect"
								src="@/assets/icons/icon_collection.png"
								width="20px"
								height="20px"
								alt=""
								@mouseenter="mousedCollect()"
								@mouseleave ="leaveCollect()"
           				 />
							<div class="explainCollect d-flex flex-row align-center" v-if="isshowCollect">
               				 <span>{{ $t("nft_explain")}}</span>
             	</div>
    </div>
     <div class="showIcon" v-else-if="pageValue.collectionType == '2' && this.collectsource != 'createFolder' ">
        <img
								class="icon_collect"
								src="@/assets/icons/icon_folder.png"
								width="20px"
								height="20px"
								alt=""
								@mouseenter="mousedCollect()"
								@mouseleave ="leaveCollect()"
           				 />
							<div class="explainCollect d-flex flex-row align-center" v-if="isshowCollect">
               				 <span>{{ $t("collection_explain")}}</span>
             	</div>
    </div>
            <img
                v-if="pageValue.disabled == '2' || pageValue.disabled == '3'"
                class="tan"
                src="@/assets/icons/icon_tan_w.png"
                width="25px"
                height="25px"
                	@mouseenter="mousedTan()"
              	@mouseleave ="leaveTan()"
                
           />
           <div class="explaintan d-flex flex-row align-center" v-if="isshowTan">
               				 <span>{{ $t("restricted_nft")}}</span>
             	</div>
    
   
    <!-- <v-img  class="avatar"  style="border: solid 2px #ffffff; cursor: pointer;" :src="usersrc" @click="tocollectpage" /> -->
    <!-- <v-img v-if="otherpage" class="avatar" style="border: solid 2px #ffffff; cursor: pointer;" :src="usersrc" @click="tocollectpage" /> -->
    <slot></slot>
     <uComponents ref="ucom"></uComponents>
  </v-card>
  
</template>

<script>
import { getFileSrc } from "@/utils/file";
	import api from "@/api";
const WORK_KEY = "WORK";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";
import Avatar from "@/components/avatar/index";

export default {
  components: {	Avatar	},
  props: {
    hash: {
      type: String,
    },
    pageValue: {
      type: Object,
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    otherpage: {
      type: Boolean,
      default: false
    },
    type:{
       type: String,
    },
     collectfrom: {
        type: String,
      
      },
      collectsource:{
         type: String,
      },
      did:{
         type: String,
      },
      addType:{
         type: String,
      },
      nftList:{
      type:Array
    }
  },
  data: () => ({
    src: undefined,
    usersrc: "",
    defaultImg,
    nfType:'',
    isshowCollect:false,
    isshowTan:false
  }),
  watch: {
    hash: {
      handler: async function (hash) {
        if (!hash) {
          hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
        this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
  },
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},
  async mounted() {
    if(this.pageValue.creatorPhoto != null  )
    {
      this.usersrc = await getFileSrc(AVATAR_KEY, this.pageValue.creatorPhoto);
    }else{
       this.usersrc = defaultImg;
    }
    	if(this.pageValue.listAmount){
    		this.pageValue.amount=this.pageValue.listAmount
      }
      if(this.pageValue.nftType == 'ERC721'){
        this.nfType = '721'
      }else{
         this.nfType = '1155'
      }
  },
  methods: {
    tocollectpage(){
this.$router.push({
          name: "Otherpage",
          query: { did: this.pageValue.owner },
        });
    },
    
    mousedCollect(){
				this.isshowCollect = true
			},
			leaveCollect(){
				this.isshowCollect = false
      },
       mousedTan(){
				this.isshowTan = true
			},
			leaveTan(){
				this.isshowTan = false
			},
   async imgClick() {
     console.log("wxl --- ssss",this.pageValue,this.collectsource,this.type)
   
      let url
      if(this.pageValue.linkUrl)
      { 
       url = this.pageValue.linkUrl
      }else{
       url = this.pageValue.address 
      }
    debugger
      if((this.collectsource == 'createCollect' || this.collectsource == 'firspublish'
      || this.collectsource == 'detaillink' || this.collectsource == 'otherpage') && this.pageValue.collectionType == '1'){
         if (this.type == "createorPage" ) {
        
            this.$router.push({
             path: `/collection_create/${url}/${this.collectsource}/${this.did}`,   
        });
       
      }else{
        let owner;
        if(this.did){
          owner = this.did
        }else{
          owner = this.pageValue.owner
        }
        this.$router.push({
          
             path: `/collection/${url}/${this.collectsource}/${owner}`,   
        });
      }
      }else{
        if(this.addType == 'addFolder'){
          debugger
  
          let nftAddress = "";
        for (let index = 0; index < this.nftList.length; index++) {
         nftAddress += this.nftList[index].nftId + ","
          
        }
        nftAddress = nftAddress.substring(0, nftAddress.lastIndexOf(','))	
        console.log("yyyyyyyyyyyyyyy",this.nftList)
        let params = {
          nftIds:nftAddress,
          owner:this.$store.state.did,
          folderId:this.pageValue.id
        }
        let res = await api.home.addFolder(params)
        if(res.success == true)
        {
          //   this.$toast("error", this.$t("amCreateFalseTitle2"));
		   this.$toast("success", this.$t("add_success"));
        }else{
          this.$toast("error", res.msg);
          return
        }

       setTimeout(()=>{
          window.eventBus.$emit("closeChooseBtn");
       },2000)
        }else{
           if (this.type == "createorPage" ) {
                  // source From Folder
           this.$router.push({
             path: `/folder_create/${url}/${this.collectsource}/${this.did}`,   
        });
           }else{
              let owner;
        if(this.did){
          owner = this.did
        }else{
          owner = this.pageValue.owner
        }
                  // source From Folder
           this.$router.push({
             path: `/folder/${url}/${this.collectsource}/${owner}`,   
        });
           }
       
        }
      
        

      }
     
    },
  },
};
</script>


<style lang="scss" scoped>
 .tan{
         position: absolute;
        top:180px;
        right: 8px;
      }
      .explaintan{
        	width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 210px;
							right: 10px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
      }
.v-card {
  width: 100%;
  height: 220px;
  background-color: #270645;
  border-radius: 5px;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #270645;

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
  }

  &:hover {
    border: solid 2px #7800f4;
  }

  .img {
    object-fit: cover;
    opacity: 0.85;
  }

  .name {
    position: absolute;
    font-family: Helvetica;font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    width: 150px;
    color: #ffffff;
    top: 18px;
    left: 18px;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nfttype{
    width: 40px;
	height: 19px;
	background-color: #ffffff;
	border-radius: 9px;
     position: absolute;
    top: 42px;
    left: 17px;
    text-align: center;
     .type{
       	font-family: Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 19px;
	letter-spacing: 0px;
	color: #270645;
    
    
  }
  }
 

  .num {

    position: absolute;
    font-family: Helvetica;font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 40px;
    left: 65px;
  }
  .otherNum{
     position: absolute;
    font-family: Helvetica;font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 40px;
    left: 20px;
  }

  .avatar {
    position: absolute;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    bottom: 20px;
    top: 150px;
    left: 90px;
  }
   .showIcon{
    .icon_collect{
						position: absolute;
						cursor: pointer;
						top: 20px;
						right: 10px;
					}
    	.explainCollect{
							width: 185px;
							height: 56px;
							background-color: #270645;
							border-radius: 3px;
							position: absolute;
							top: 45px;
							right: 10px;
							span {
								font-family:Helvetica;
								font-size: 12px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 15px;
								letter-spacing: 0px;
								color: #ffffff;
								margin-left: 13px;
								margin-right: 13px;
								}
						}
  }
  .mobileavatar{
     position: absolute;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    bottom: 20px;
    top: 150px;
    left: 145px;
  }
}
</style>