<template>
  <div class="sort-select">
    <v-menu v-model="show" offset-y nudge-bottom="10px" class="menu">
      <template v-slot:activator="{ on, attrs, value: v }">
        <Chip v-bind="attrs" v-on="on">
          <span>{{ name }}</span>
          <v-icon class="ml-2" color="primary" v-if="v">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
       <template class="overflow-hidden">
          <List :list="list" :value="value" @input="input" class="list" > </List>
       </template>
     
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import List from '@/components/List.vue';

export default {
  name: 'SortSelect',
  components: { Chip, List },
  props: {
      value: [String, Number],
      list: {
        type: Array,
        default: () => []
      }
  },
  data: function() {
    return {
        show: false
    };
  },
  computed: {
    selected: function() {
      return this.list.find(v =>
	   v.value === this.value);
    },
    name: function() {
      return this.selected ? this.selected.label :  '';
    }
  },
  methods: {
    input:function (e) {
      this.$emit('input',  e)
    }
  }
};
</script>

<style lang="scss" scoped>
.v-menu__content{
   z-index: 101 !important;

}
.sort-select {
  .v-menu{
    z-index: 998;
    // &::v-deep .v-.v-menu__content{
    //  position:fixed !important;
    // }
    
  }
    margin-left: auto;
  
}
</style>
