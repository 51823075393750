<template>
  <div class="marketplace" :class="{mobile: isMobile}">
    <div class="top mt-n4">
      <SearchInput class="search" style="margin-bottom:-20px"  :placeholder="this.$t('search_collection')" @SearchChange="SearchChange" />
      <div class="top-right">
        <!-- <img class="nft" :src="nftImage" @click="mode_click('nft')" />
        <img class="collection" :src="collectionImage" @click="mode_click('collection')" />
        <SortSelect v-if="!isMobile && showModel == 'nft'" :list="selectList1" v-model="activeSort1" /> -->
        <SortSelect v-if="!isMobile" :list="selectList2" v-model="activeSort2" />
         <img  :class="[rotate?'go':'']" class="refresh" src="@/assets/icons/icon_refresh.png"  @click="refresh" />
      </div>
    </div>

    <div class="main">
      <div class="collect">
        <div v-if="collects.length == 0 && !isShowLoading && showNotFond" class="empty d-flex flex-column">
          <div class="notFound">{{$t('market_collectionNotFound')}}</div>
          <div class="tryAnother">{{$t('market_collectionNotFoundDes')}}</div>
        </div>
        <div class="card" v-for="(item, index) in collects" :key="index">
          <collect-card :pageValue="item" :hash="item.imgUrl" type="indexpage" collectsource="firspublish" showAvatar  v-scroll="onScroll">
          </collect-card>
        </div>
          <loading  :isShowLoading="isShowLoading" style="padding-left:500px;"></loading>
      </div>

      <!-- <div class="collect" v-if="showModel == 'nft'">
        <div class="card" v-for="(item, index) in nfts" :key="index">
          <Card type="sell" :hash="item.hash" v-scroll="onScroll" :value="item" owerfrom="market" />
        </div>
      </div> -->
    </div>
    <!-- <div class="right_bottom" v-if="!isMobile">
	  <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
	</div> -->
   <div class="right_bottom1" v-if="!isMobile">
        <img
          v-if="!openmsg_group"
          src="@/assets/image/btn_service-message.png"
          @click="openServiceMessage"
          alt=""
        />
      </div>
      <div v-if="openmsg_group" style="position: fixed; right: 20px; bottom: 20px; z-index: 9999;">
         <message-service @getOpen="OpenMsg_group" v-if="openmsg_group" :currfriend="currfriend_group"></message-service>
      </div> 
  	<v-dialog v-model="opencard">
	  <carousel class="otherpage" :list="nfts" v-if="opencard" @getOpen="OpenCard"></carousel>
	</v-dialog>
  </div>
</template>

<script lang="js">
// import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/CardList.vue";
import SortSelect from '@/components/SortSelect.vue';
import api from "@/api"
import Carousel from "@/components/carousel";
import { mapState } from 'vuex'
import SearchInput from '@/components/SearchInput.vue'

import CollectCard from '@/components/workCard/CollectCard.vue';
import TrendCard from '@/components/workCard/TrendCard.vue';
import Card from "@/components/workCard/Card.vue";
import MessageService from "@/components/popup/messageService.vue";
import Loading from "@/components/loading.vue";
	import { getSettingArr } from "@/utils/helper";

let last_time = 0;
let carouselPosition = 0;

export default {
  name: 'Marketplace',
  components: { CardList, SortSelect, Carousel, SearchInput, CollectCard, Card,MessageService,TrendCard,Loading},
  props: {},
  data: function () {
    return {
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      selectList1: [
        { value: "", label: "All" },
      ],
      // activeSort1: sessionStorage.getItem('activeSort1') ? sessionStorage.getItem('activeSort1') : "",

      selectList2: [
        { value: 2, label: this.$t('marketType1') },
        { value: 9, label: this.$t('marketType5') },
      ],
      activeSort2: sessionStorage.getItem('launch_activeSort2') ? Number(sessionStorage.getItem('launch_activeSort2')) : 2,
      collects: [],
      nfts: [],
      recommendList:[],
      activeSlide: 0,
      carouselPage: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: false,
      isShowLoading: false,
       showNotFond:false,
      showModel: "nft", // collection
      nftImage: require("@/assets/icons/icon_cj_nft.png"),
      collectionImage: require("@/assets/icons/icon_cj_collection_g.png"),
      rotate:false,
      opencard: false,
      searchInput:'',
      isBtnEabled: true,
      currfriend_group:{},
      openmsg_group: false,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        // searchInput: this.searchInput,/
        // activeTab: this.activeTab,
        // activeSort1: this.activeSort1,
        activeSort2: this.activeSort2,
      }
    },
    //   ...mapState({
    //   searchInput: (state) => state.searchInput,
    // }),
  },
  watch: {
    handler: {
      handler: function () {
        clearTimeout(last_time);
        last_time = setTimeout(() => {
          this.nfts = [];
          this.collects = []
          this.pageNumber = 1;
          this.getColleectList();
        }, 600)
      },
      deep: true
    }
  },
  methods: {
    openServiceMessage(){
      console.log("openServiceMessage")
        this.currfriend_group.fansAddress = "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
        this.currfriend_group.fansName = "Uptick CS"
        this.openmsg_group = true;

    },
     OpenMsg_group(e) {
        this.openmsg_group = e;
      },
    // loadChain() {
    //   let chainToken = localStorage.getItem("ChainToken");
    //   if(chainToken) {
    //       let coinData = JSON.parse(chainToken);
    //       coinData.forEach(ele => {
    //         this.selectList1.push({ value: ele.name, label: ele.name });
    //       });
    //   }
    // },
      async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
           let res2 = await api.home.isNewMessage({address:this.$store.state.did});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContract,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
    
    SearchChange(val) {
      this.$gtag.event('集合页搜索', { 'event_category': 'Click', 'event_label': 'search' })
      this.searchInput = val
      clearTimeout(last_time);
      last_time = setTimeout(() => {
        this.nfts = [];
        this.collects = []
        this.pageNumber = 1;
        this.getColleectList();
      }, 600)
    },
    // mode_click(mode) {
    //   this.showModel = mode;
    //   mode == "nft" ? this.nftImage = require("@/assets/icons/icon_cj_nft.png") : this.nftImage = require("@/assets/icons/icon_cj_nft_g.png");
    //   mode == "collection" ? this.collectionImage = require("@/assets/icons/icon_cj_collection.png") : this.collectionImage = require("@/assets/icons/icon_cj_collection_g.png");
    //   this.pageNumber = 1;
    //   this.collects = []
    //   this.nfts = []
    //   this.getColleectList();
    // },
    refresh() {
      this.rotate = true;
      setTimeout(() => { this.rotate = false }, 2000);
      this.nfts = []
      this.collects = []
      this.pageNumber = 1;
      this.getColleectList();
      window.eventBus.$emit("clearSearch");

    },
   
    async getColleectList() {
      // sessionStorage.setItem('activeTab',this.activeTab)
      // sessionStorage.setItem('activeSort1', this.activeSort1)
      sessionStorage.setItem('launch_activeSort2', this.activeSort2)

      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        sortType:this.activeSort2,
        nftShowType:3,
        searchKey: encodeURIComponent(this.searchInput|| ""),
      };
      this.isShowLoading = true;

        return api.home.getGalleryList(params).then(res => {
          let { list, totalPage } = res.data;
          this.totalPage = totalPage;
              
          if(this.pageNumber == 1) {
            this.nfts = []
            this.collects = []
          }
          this.collects = this.collects.concat(list);
          this.isShowLoading = false;
          this.showNotFond = true
          if (this.collects.length > 0) {
            this.showRightBottom = true
          } else {
            this.showRightBottom = false
          }
        })
      
    },
    async onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (scrollTop + clientHeight >= scrollHeight && (this.totalPage > this.pageNumber)) {
        this.pageNumber++;
        await this.getColleectList();
      }

    },
    openCarousel() {
      this.opencard = true;
    },
    OpenCard(e) {
      this.opencard = e;
    },
    onLangChange() {
      this.selectList2 = [
       { value: 2, label: this.$t('marketType1') },
        { value: 8, label: this.$t('marketType5') },
      ];
      this.isRefsh++;
    },
    carouselDown(e) {
      // console.log("carouselDown",e);
      if(e.button == 0) {
        carouselPosition = e.clientX;
      }
    },
    carouselUp(e) {
      // console.log("carouselUp",e);
      if(e.button == 0) {
        if(carouselPosition > e.clientX) {
          this.activeSlide++;
        } else if(carouselPosition < e.clientX) {
          this.activeSlide--;
        }
      }
    }
  },
  mounted() {
    this.showNoRead();
    window.eventBus.$on('LangChange', this.onLangChange);
  
  
    // this.activeSort2 = 1;
    this.getColleectList();
    // this.loadChain();


  },
};
</script>

<style lang="scss" scoped>
.marketplace{
   ::v-deep.sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
  .recommendTitle{
    width: 100%;
    text-align: center;
    margin: auto;
    font-family: Helvetica;
	font-size: 30px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 70px;
	letter-spacing: 0px;
	color: #270645;
  }
  .recommendCard {
    display: flex;
    user-select: none;
    &::v-deep .v-carousel__controls{
      background:white;
      top:420px;
      .v-btn {
    color:   #766983!important;
}
  .v-btn--active {
    color:#7800f4!important

  }
   .v-btn--active::before {
    background:white;
    
  }
  .v-icon{
    font-size:10px!important;
  }


    }
   



    // justify-content: space-between;

    .card {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-right: 22px;

      // justify-content: space-between;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
   .refresh{
  height: 18px;
  width: 18px;
  margin-left: 15px;
  margin-top: -4px;
}
 
    
    .go{
        transform:rotate(360deg);
        transition: all 2s;
        pointer-events: none;
        user-select: none;
    }
}
.right_bottom {
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 200;

  img {
	display: block;
	width: 59px;
	height: 59px;
	margin-top: 35px;
  }
}
.right_bottom1{
   position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 200;

  img {
	display: block;
	width: 59px;
	height: 59px;
	margin-top: 35px;
  }
}
.top {
  // display: flex;
  position: relative;

  .search {
    width: 400px;
  }

  .top-right {
     ::v-deep .sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
  > .v-tabs {
    border-bottom: solid 1px #e3e3e3;

    .v-tab {
      padding: 0;
      margin-right: 50px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 120px;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .search-inpt {
    width: 600px;
    height: 41px;
    background-color: #f0f0f0;
    border-radius: 20px;
    outline-color: #f0f0f0;
  }
    width: 320px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -15px;

    img {
      cursor: pointer;
    }

    .nft {
      margin-left:20px;
      width: 22px;
      height: 28px;
    }

    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
  }
}

.main {
  margin: 20px 0 60px;

  .collect {
    display: flex;
    flex-wrap: wrap;
     .empty {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      .notFound{
        font-family: Helvetica;
        font-size: 24px;
        font-weight: bold;

      }
      .tryAnother{
          font-family: Helvetica;
        font-size: 18px;
        width: 320px;
        text-align: center;
      }
    }

    .card {
      margin-top: 16px;
      margin-bottom: 16px;
      margin-right: 30px;

      &:nth-child(5n) {
        margin-right: 0;
      }
     
    }
  }

 

}

.marketplace.mobile {
  .top {
    display: flex;
    align-items: center;
    height: 50px;

    .search {
      margin-bottom: unset !important;
    }

    .top-right {
      width: unset;
      position: unset;
      margin-top: 16px;
    }

    .collection {
      margin-left: 20px;
    }

    .refresh{
      height: 25px;
      width: 25px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .collect {
    .card {
      width: 100%;
      margin-right: unset;
    }
  }

  .recommendCard {
    .card {
      width: 100%;
      margin-right: 10px;
    }
    .v-carousel {
      height: 420px !important;
    }
  }
}
</style>
